import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import TextField from 'components/form/TextField';
import Link from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { signUpFx } from 'models/session/effects';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type FormValues = {
	email: string;
	password: string;
};

function SignUp() {
	// Client can be given link to sign-in page with email in query params, for convenience.
	const initialEmail = useMemo(
		() => new URLSearchParams(window.location.search).get('email') || '',
		[]
	);

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			email: initialEmail,
			password: '',
		},
		mode: 'onBlur', // "onChange"
	});

	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	async function onSubmit({ email, password }: FormValues) {
		setLoading(true);

		try {
			await signUpFx({ login: email, password });
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 422 || error.response.status === 403)
			) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}
		}

		setLoading(false);
	}

	return (
		<div className={styles.container} data-test="container-sign-up">
			<div className={styles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={styles.title}>
					Welcome
				</Typo>

				<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
					<TextField
						control={control}
						label="Work email"
						name="email"
						type="email"
						data-test="email"
						required
						fullWidth
						size="large"
					/>

					<PasswordField
						control={control}
						label="Password"
						name="password"
						autoComplete="current-password"
						data-test="password"
						required
						fullWidth
						size="large"
					/>

					<Button
						type="submit"
						size="large"
						loading={isLoading}
						className={styles.formButton}
						data-test="create-acc-bt"
					>
						Create account
					</Button>

					{serverError && !isLoading && (
						<Alert className={styles.errorMessage} severity={AlertSeverity.error}>
							{serverError}
						</Alert>
					)}
				</form>

				<Typo color="secondary" variant="D/Regular/Meta" className={styles.terms}>
					By creating an account, I agree to the{' '}
					<Link inherit target="_blank" className={styles.termsLink} to="/documents/terms.pdf">
						Terms of service.
					</Link>
				</Typo>

				<Typo variant="D/Medium/Body-S" className={styles.haveAccount}>
					Already have an account?{' '}
					<Link data-test="sign-in-link" to={PATHS.SIGN_IN}>
						Sign in
					</Link>
					.
				</Typo>
			</div>
		</div>
	);
}

export default SignUp;
