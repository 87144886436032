import { useStore } from 'effector-react';
import { generatePath } from 'react-router';
import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

const tabLabels = {
	summary: 'Summary',
	files: 'Files',
	connections: 'Connections',
};

type Props = {
	tab: 'summary' | 'files' | 'connections';
	bucket: S3BucketItem;
};

function S3BucketHeader(props: Props) {
	const { tab, bucket } = props;

	const s3Regions = useStore(s3RegionsByKeyword);

	const regionDescription = s3Regions[bucket.region]?.description || '';

	return (
		<Header
			showSensitiveSwitcher
			breadcrumbProps={{
				steps: [
					...s3BucketsSteps,
					{
						path: generatePath(PATHS.S3_BUCKETS_ITEM, {
							id: bucket.id,
							tab: 'summary',
						}),
						name: bucket.name,
					},
				],
				finalStep: tabLabels[tab],
			}}
			titleBlock={
				<div className={styles.headerContainer}>
					<div>
						<Typo
							variant="D/Medium/H400-Page-Title"
							dataTest="s3-bucket-header-name"
							className={styles.ellipsisText}
						>
							{bucket.name}
						</Typo>
						<Typo
							variant="D/Regular/Body-S"
							color="secondary"
							dataTest="s3-bucket-header-region"
							className={styles.ellipsisText}
						>
							{regionDescription}
						</Typo>
					</div>
					<ExternalLink href={bucket.aws_console_url} className={styles.link}>
						View in AWS console
					</ExternalLink>
				</div>
			}
		/>
	);
}

export { S3BucketHeader };
