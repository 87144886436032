import { DataMapJson } from './dto';

const dataMapJsonExample: DataMapJson = {
	elements: [
		{
			id: 11,
			type: 'asset',
			name: 'nginx-controller',
			namespace: 'stats',
			data: {
				is_external: false,
				type: 'internal',
				k8s_types: ['ClusterIP', 'LoadBalancer'],
				sensitivity: 'Medium',
			},
			groups: [1],
			cluster_id: 1,
			region: 'us-west-1',
		},
		{
			id: 12,
			type: 'asset',
			name: 'api-backend',
			namespace: 'stats',
			data: {
				is_external: false,
				type: 'internal',
				k8s_types: ['ClusterIP'],
				sensitivity: 'Medium',
			},
			groups: [1],
			cluster_id: 1,
			region: 'us-west-1',
		},
		{
			id: 25,
			type: 'asset',
			name: 'auth-service-stage',
			namespace: 'stats',
			data: {
				is_external: false,
				type: 'internal',
				k8s_types: ['ClusterIP'],
				sensitivity: 'Medium',
			},
			groups: [1],
			cluster_id: 1,
			region: 'us-west-1',
		},
		{
			id: 26,
			type: 'asset',
			name: 'auth-service-prod',
			namespace: 'dev',
			data: {
				is_external: false,
				type: 'internal',
				k8s_types: ['ClusterIP'],
				sensitivity: 'Medium',
			},
			groups: [1],
			cluster_id: 1,
			region: 'us-west-1',
		},
		{
			id: 3,
			type: 'asset',
			name: 'other_in',
			namespace: 'stage',
			data: {
				is_external: true,
				type: 'other_in',
				sensitivity: 'Medium',
			},
			groups: [1],
			cluster_id: 2,
			region: 'us-west-2',
		},
		{
			id: 4,
			type: 's3_bucket',
			name: 'bucket 1',
			data: {
				sensitivity: 'Medium',
				data_types: [1, 2, 3],
			},
			cluster_id: 0,
			region: 'us-west-3',
			namespace: '',
			groups: [1],
		},
	],

	relations: [
		{
			sender: {
				id: 11,
				type: 'asset',
			},
			receiver: {
				id: 3,
				type: 'asset',
			},
			data_types: [1, 4],
			is_encrypted: false,
		},
		{
			sender: {
				id: 3,
				type: 'asset',
			},
			receiver: {
				id: 11,
				type: 'asset',
			},
			data_types: [1],
			is_encrypted: false,
		},
		{
			sender: {
				id: 11,
				type: 'asset',
			},
			receiver: {
				id: 12,
				type: 'asset',
			},
			data_types: [1],
			is_encrypted: false,
		},
		{
			sender: {
				id: 12,
				type: 'asset',
			},
			receiver: {
				id: 25,
				type: 'asset',
			},
			data_types: [1],
			is_encrypted: false,
		},
		{
			sender: {
				id: 12,
				type: 'asset',
			},
			receiver: {
				id: 26,
				type: 'asset',
			},
			data_types: [1],
			is_encrypted: false,
		},
	],
};

export { dataMapJsonExample };
