import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { AssetConnectionsTableItem } from 'models/assetDataFlows/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import styles from '../index.module.css';

type Props = {
	assetConnection: AssetConnectionsTableItem;
};

function TableRow({ assetConnection }: Props) {
	const history = useHistory();
	const { entity_name, sensitivity, data_types, last_seen, interacts_with } = assetConnection;

	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);
	function onRowClick() {
		const path = generatePath(PATHS.S3_BUCKETS_ITEM, {
			id: interacts_with,
			tab: 'summary',
		});
		history.push(path);
	}

	return (
		<GridRow
			className={styles.rowContainer}
			border
			data-test="connection-table-row"
			hover
			onClick={onRowClick}
		>
			<GridCell withoutBlur>
				{assetConnection.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
			</GridCell>

			<GridCell>{entity_name}</GridCell>

			<GridCell>
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell>
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell>
				<Tooltip title={lastSeenValue.date}>{lastSeenValue.diff}</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
