import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { AssetGroupChipList } from 'components/AssetGroupChip';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { AssetDataFlowsTableItem } from 'models/assetDataFlows/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import DataFlowIcon from 'views/SuperAssets/DataFlowIcon';
import styles from '../index.module.css';

type Props = {
	assetDataFlow: AssetDataFlowsTableItem;
};

function DataFlowTableItem({ assetDataFlow }: Props) {
	const {
		flow_direction,
		interacts_with,
		firstAssetId,
		name,
		type,
		k8s_types,
		sensitivity,
		data_types,
		last_seen,
		namespace,
		groups,
		is_encrypted,
	} = assetDataFlow;
	const history = useHistory();
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	function onRowClick() {
		const path = generatePath(PATHS.SERVICE_ITEM, {
			tab: 'data-flows',
			details_with: interacts_with,
			id: firstAssetId,
			flow_direction,
		});
		history.replace(path + history.location.search);
	}

	const displayName = getAssetNameByType(type, name);
	const k8sLabel = k8s_types?.join(', ');

	return (
		<GridRow
			className={styles.rowContainerV2}
			onClick={onRowClick}
			hover
			border
			data-test="data-flow-table-row"
		>
			<GridCell className={styles.directionAndTls} withoutBlur>
				<DataFlowIcon direction={flow_direction} className={styles.icon} />
				{is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
			</GridCell>

			<GridCell className={styles.gapCell}>
				{displayName}
				<Typo variant="D/Medium/Meta" color="secondary">
					{k8sLabel}
				</Typo>
			</GridCell>

			<GridCell>{namespace || DASH_SIGN}</GridCell>

			<GridCell>
				<AssetGroupChipList groups={groups} />
			</GridCell>

			<GridCell>
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell>
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell>
				<Tooltip title={lastSeenValue.date}>{lastSeenValue.diff}</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export default DataFlowTableItem;
