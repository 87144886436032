import { get, post, put } from 'api/Api';
import {
	ChangePasswordBody,
	CheckEmailBody,
	CheckEmailJson,
	RestorePasswordBody,
	Session,
	SignInBody,
	SignUpBody,
	SignUpWithTokenBody,
	SSOLoginJson,
} from './dto';

async function getSession() {
	return get<Session>('v1/session');
}

async function signIn(body: SignInBody) {
	return post<Session>('v1/signin', body);
}

async function signUp(body: SignUpBody) {
	return post('v1/signup', body);
}

async function signUpWithToken(body: SignUpWithTokenBody) {
	return post<Session>('v1/clients/accept-invite', body);
}

async function signOut() {
	await post('v1/signout');
}

async function changePassword(body: ChangePasswordBody) {
	return put<Session>('v1/client/change-password', body);
}

async function restorePassword(body: RestorePasswordBody) {
	return post<Session>('v1/clients/restore-password', body);
}

async function checkEmail(body: CheckEmailBody) {
	return post<CheckEmailJson>('v1/clients/email-check', body);
}

async function getSSOLoginUrl(body: CheckEmailBody) {
	return post<SSOLoginJson>('v1/sso/login-url', body);
}

export {
	signUp,
	signUpWithToken,
	signIn,
	signOut,
	getSession,
	changePassword,
	restorePassword,
	checkEmail,
	getSSOLoginUrl,
};
