import Typo from 'components/typography/Typo';
import {
	ClusteringEventJson,
	DiscoveryCompleteJson,
	ReleaseUpdateJson,
	EventJson,
	ExternalAssetConflictJson,
	PolicyEventJson,
	PolicyS3EventJson,
	StorageEventJson,
	NewDataTypeEventJson,
} from 'models/eventsV2/dto';
import { eventTitleAsComponent } from '../EventRow';
import { formatWithToday } from '../utils';
import AssetEvent from './AssetEvent';
import ClusteringEvent from './ClusteringEvent';
import { DataTypeEvent } from './DataTypeEvent';
import DiscoveryCompleteEvent from './DiscoveryCompleteEvent';
import ExternalAssetConflictEvent from './ExternalAssetConflictEvent';
import styles from './index.module.pcss';
import PolicyEvent from './PolicyEvent';
import PolicyS3Event from './PolicyS3Event';
import ReleaseUpdateEvent from './ReleaseUpdateEvent';
import { StorageEvent } from './StorageEvent';

function isReleaseUpdateEvent(event: EventJson): event is ReleaseUpdateJson {
	return (
		event.type === 'dar_release_update' ||
		event.type === 'dar_release_update_critical' ||
		event.type === 'dim_release_update' ||
		event.type === 'dim_release_update_critical'
	);
}

function isDiscoveryCompleteEvent(event: EventJson): event is DiscoveryCompleteJson {
	return event.type === 'discovery_complete';
}

function isDataTypeEvent(event: EventJson): event is NewDataTypeEventJson {
	return event.type === 'new_data_type';
}

function isPolicyEvent(event: EventJson): event is PolicyEventJson {
	return event.type === '3rd_party_policy_violation' || event.type === 'policy_violation';
}

function isPolicyS3Event(event: EventJson): event is PolicyS3EventJson {
	return event.type === 'policy_unencrypted_s3_bucket' || event.type === 'policy_public_s3_bucket';
}

function isClusteringEvent(event: EventJson): event is ClusteringEventJson {
	return event.type === 'email_clustered';
}

function isExternalAssetConflictEvent(event: EventJson): event is ExternalAssetConflictJson {
	return event.type === 'custom_asset_rule_conflict';
}

function isStorageEvent(event: EventJson): event is StorageEventJson {
	return event.type === 'started_storing_data' || event.type === 'started_storing_datatype';
}

type Props = {
	event: EventJson;
};

function Header(props: Props) {
	const { event } = props;

	const title = eventTitleAsComponent({ event });

	const formattedLocal = formatWithToday(event.created_at);

	return (
		<>
			<Typo
				variant="D/Medium/Body"
				className={styles.detailsHeader}
				dataTest="event-details-header"
			>
				{title}
			</Typo>

			<span className={styles.detailsTime}>{formattedLocal}</span>
		</>
	);
}

function EventDetails(props: Props) {
	const { event } = props;

	return (
		<>
			<Header event={event} />

			{isReleaseUpdateEvent(event) ? (
				<ReleaseUpdateEvent event={event} />
			) : isDiscoveryCompleteEvent(event) ? (
				<DiscoveryCompleteEvent event={event} />
			) : isDataTypeEvent(event) ? (
				<DataTypeEvent event={event} />
			) : isPolicyEvent(event) ? (
				<PolicyEvent event={event} />
			) : isPolicyS3Event(event) ? (
				<PolicyS3Event event={event} />
			) : isClusteringEvent(event) ? (
				<ClusteringEvent event={event} />
			) : isStorageEvent(event) ? (
				<StorageEvent event={event} />
			) : isExternalAssetConflictEvent(event) ? (
				<ExternalAssetConflictEvent event={event} />
			) : (
				<AssetEvent event={event} />
			)}
		</>
	);
}

export default EventDetails;
