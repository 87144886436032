import { AssetGroupDataFlowJson, AssetGroupDataFlowsJson } from './dto';

const assetGroupDataFlowsExample: AssetGroupDataFlowJson[] = [
	{
		flow_direction: 'receiver',
		interacts_with: 3,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558386500,
		is_encrypted: false,
	},
	{
		flow_direction: 'both',
		interacts_with: 11,
		sensitivity: 'High',
		data_types: [2, 3, 4, 5, 6, 7, 10],
		last_seen: 1661558186500,
		is_encrypted: false,
	},
	{
		flow_direction: 'receiver',
		interacts_with: 1,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558286500,
		is_encrypted: false,
	},
];

const assetGroupDataFlowListExample: AssetGroupDataFlowsJson = {
	dataflows: assetGroupDataFlowsExample,
	total: assetGroupDataFlowsExample.length,
	// total_filtered: 2, // Optional
};

export { assetGroupDataFlowListExample };
