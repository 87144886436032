import { createEffect } from 'effector';
import dropSession from 'services/dropSession';
import {
	changePassword,
	getSession,
	restorePassword,
	signIn,
	signOut,
	signUp,
	signUpWithToken,
} from './api';
import { SignUpBody, SignUpWithTokenBody } from './dto';

export const signInFx = createEffect(signIn);

export const changePasswordFx = createEffect(changePassword);

export const restorePasswordFx = createEffect(restorePassword);

export const signUpFx = createEffect(async (params: SignUpBody) => {
	await signUp(params);
	return await signIn(params);
});

export const signUpWithTokenFx = createEffect(async (params: SignUpWithTokenBody) => {
	return await signUpWithToken(params);
});

export const signOutFx = createEffect(async () => {
	await signOut();
	dropSession();
});

export const getSessionFx = createEffect(getSession);
