import { del, get, patch, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { GatewayJson, GatewaysJson, GatewayUpdateJson } from './dto';
import {
	offlineGatewayJsonExample,
	onlineGatewayJsonExample,
	gatewaysJsonExample,
} from './examples';

async function getGateways() {
	return get<GatewaysJson>('v1/gateway').then(checkResponse(gatewaysJsonExample));
}

async function createGateway(payload: GatewayUpdateJson) {
	return post<GatewayJson>('v1/gateway', payload).then(
		checkResponse(offlineGatewayJsonExample, onlineGatewayJsonExample)
	);
}

async function updateGatewayName(payload: GatewayJson) {
	return patch<GatewayJson>(`v1/gateway/${payload.id}`, { name: payload.name }).then(
		checkResponse(offlineGatewayJsonExample, onlineGatewayJsonExample)
	);
}

async function updateGatewayRegion(payload: GatewayJson) {
	return patch<GatewayJson>(`v1/gateway/${payload.id}`, { region: payload.region }).then(
		checkResponse(offlineGatewayJsonExample, onlineGatewayJsonExample)
	);
}

async function deleteGateway(id: number) {
	return del(`v1/gateway/${id}`).then(() => id);
}

export { getGateways, createGateway, updateGatewayName, updateGatewayRegion, deleteGateway };
