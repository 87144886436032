import cn from 'classnames';
import commonStyles from 'assets/styles/index.module.pcss';
import AssetChip from 'components/AssetChip';
import Icon from 'components/Icon';
import PiiType from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { dataTypesById } from 'models/dataTypes/store';
import {
	ClusteringEventJson,
	DiscoveryCompleteJson,
	EventJson,
	ExternalAssetConflictJson,
	NewDataTypeEventJson,
	PolicyEventJson,
	PolicyS3EventJson,
	ReleaseUpdateJson,
	StorageEventJson,
} from 'models/eventsV2/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import { formatWithToday } from '../utils';
import DataTypesWithMore from './DataTypesWithMore';
import styles from './index.module.pcss';

function isReleaseUpdateEvent(event: EventJson): event is ReleaseUpdateJson {
	return (
		event.type === 'dar_release_update' ||
		event.type === 'dar_release_update_critical' ||
		event.type === 'dim_release_update' ||
		event.type === 'dim_release_update_critical'
	);
}

function isDiscoveryCompleteEvent(event: EventJson): event is DiscoveryCompleteJson {
	return event.type === 'discovery_complete';
}

function isDataTypeEvent(event: EventJson): event is NewDataTypeEventJson {
	return event.type === 'new_data_type';
}

function isPolicyEvent(event: EventJson): event is PolicyEventJson {
	return event.type === '3rd_party_policy_violation' || event.type === 'policy_violation';
}

function isPolicyS3Event(event: EventJson): event is PolicyS3EventJson {
	return event.type === 'policy_unencrypted_s3_bucket' || event.type === 'policy_public_s3_bucket';
}

function isStorageEvent(event: EventJson): event is StorageEventJson {
	return event.type === 'started_storing_data' || event.type === 'started_storing_datatype';
}

function isClusteringEvent(event: EventJson): event is ClusteringEventJson {
	return event.type === 'email_clustered';
}

function isExternalAssetConflictEvent(event: EventJson): event is ExternalAssetConflictJson {
	return event.type === 'custom_asset_rule_conflict';
}

type Props = {
	event: EventJson;
	selected: boolean;
	onSelect: (id: number) => void;
	dataTest?: string;
	filterIds?: number[];
};

// eslint-disable-next-line complexity
function eventTitle({
	event,
	size,
	filterIds,
}: {
	event: EventJson;
	size?: string;
	filterIds?: number[];
}) {
	let title = <></>;
	let titleText = '';
	const asset =
		isDataTypeEvent(event) ||
		isClusteringEvent(event) ||
		isExternalAssetConflictEvent(event) ||
		isDiscoveryCompleteEvent(event) ||
		isPolicyS3Event(event) ||
		isStorageEvent(event) ||
		isReleaseUpdateEvent(event)
			? null
			: event.event_regarding === 'requestor'
			? event.requestor
			: event.responder;
	const assetName = asset ? getAssetNameByType(asset.type, asset.name, 12) : '<unknown>';
	const assetNameText = asset ? getAssetNameByTypeText(asset.type, asset.name) : '<unknown>';
	const internalOrExternal = asset
		? asset.is_external
			? 'External connection'
			: 'Service'
		: '<unknown>';
	const assetChip = asset ? (
		<AssetChip
			size={size === 'small' ? 'extraSmall' : undefined}
			label={assetName}
			isExternal={asset.is_external}
			noTooltip
			className={cn(commonStyles.skeletonMargin, size === 'small' && styles.assetChipSmall)}
		/>
	) : null;
	const plural =
		!isExternalAssetConflictEvent(event) &&
		!isDiscoveryCompleteEvent(event) &&
		!isReleaseUpdateEvent(event) &&
		event.data_types.length > 1
			? 's'
			: '';

	switch (event.type) {
		case 'new_data_type':
			title = (
				<>
					New data type{' '}
					<PiiType
						size={size === 'small' ? 'extraSmall' : undefined}
						type={event.data_types[0]}
						className={cn(commonStyles.skeletonMargin, styles.type)}
						highlighted={filterIds?.includes(event.data_types[0])}
					/>{' '}
					in the infrastructure
				</>
			);
			titleText = `New data type [${
				dataTypesById.getState()[event.data_types[0]].name
			}] in the infrastructure`;
			break;

		case 'new_internal_receiver':
		case 'new_external_receiver':
			title = (
				<>
					{internalOrExternal} {assetChip} started receiving data
				</>
			);
			titleText = `${internalOrExternal} [${assetNameText}] started receiving data`;
			break;

		case 'new_internal_sender':
			// case 'new_external_sender':
			title = (
				<>
					{internalOrExternal} {assetChip} started sending data
				</>
			);
			titleText = `${internalOrExternal} [${assetNameText}] started sending data`;
			break;

		case 'updated_internal_receiver':
		case 'updated_external_receiver':
		case 'updated_enduser_receiver':
		case 'updated_robot_receiver':
			title = (
				<>
					{internalOrExternal} {assetChip} is receiving new data type{plural}
				</>
			);
			titleText = `${internalOrExternal} [${assetNameText}] is receiving new data type${plural}`;
			break;

		case 'updated_internal_sender':
			// case 'updated_external_sender':
			title = (
				<>
					{internalOrExternal} {assetChip} is sending new data type{plural}
				</>
			);
			titleText = `${internalOrExternal} [${assetNameText}] is sending new data type${plural}`;
			break;

		case 'policy_violation':
			title = (
				<>
					Data type{' '}
					<PiiType
						size={size === 'small' ? 'extraSmall' : undefined}
						type={event.data_types[0]}
						className={cn(commonStyles.skeletonMargin, styles.type)}
						highlighted={filterIds?.includes(event.data_types[0])}
					/>{' '}
					is outside the allowed set of services
				</>
			);
			titleText = `Data type [${
				dataTypesById.getState()[event.data_types[0]].name
			}] is outside the allowed set of services`;
			break;

		case '3rd_party_policy_violation':
			title = (
				<>
					Service {assetChip} is sharing{' '}
					<PiiType
						size={size === 'small' ? 'extraSmall' : undefined}
						type={event.data_types[0]}
						className={cn(commonStyles.skeletonMargin, styles.type)}
						highlighted={filterIds?.includes(event.data_types[0])}
					/>{' '}
					with third parties
				</>
			);
			titleText = `Service [${assetNameText}] is sharing [${
				dataTypesById.getState()[event.data_types[0]].name
			}] with third parties`;
			break;

		case 'policy_unencrypted_s3_bucket':
			title = (
				<>
					Data type{' '}
					<PiiType
						size={size === 'small' ? 'extraSmall' : undefined}
						type={event.data_types[0]}
						className={cn(commonStyles.skeletonMargin, styles.type)}
						highlighted={filterIds?.includes(event.data_types[0])}
					/>{' '}
					is stored in unencrypted S3 bucket
				</>
			);
			titleText = `S3 bucket [${event.s3_bucket.name}] is stored in unencrypted S3 bucket`;
			break;

		case 'policy_public_s3_bucket':
			title = (
				<>
					Data type{' '}
					<PiiType
						size={size === 'small' ? 'extraSmall' : undefined}
						type={event.data_types[0]}
						className={cn(commonStyles.skeletonMargin, styles.type)}
						highlighted={filterIds?.includes(event.data_types[0])}
					/>{' '}
					is stored in publicly available S3 bucket
				</>
			);
			titleText = `S3 bucket [${event.s3_bucket.name}] is outside the allowed set of services`;
			break;

		case 'email_clustered':
			title = <>Data type in API url path detected</>;
			titleText = 'Data type in API url path detected';
			break;

		case 'custom_asset_rule_conflict':
			title = <>Conflict in rules for custom external connections</>;
			titleText = 'Conflict in rules for custom external connections';
			break;

		case 'discovery_complete':
			title = <>Initial discovery of your infrastructure is complete</>;
			titleText = 'Initial discovery of your infrastructure is complete';
			break;

		case 'dim_release_update':
			title = (
				<div className={styles.releaseUpdate}>
					<Icon name="warning" size={18} className={styles.releaseUpdateIcon} />
					Data-in-motion Sensor update is now available
				</div>
			);
			titleText = 'A data-in-motion sensor update is now available. The new package includes:';
			break;

		case 'dim_release_update_critical':
			title = (
				<div className={styles.releaseUpdate}>
					<Icon name="Danger/FilledRed" size={18} className={styles.releaseUpdateIcon} />
					Data-in-motion Sensor critical update is now available
				</div>
			);
			titleText =
				'A critical update for the data-in-motion sensor is now available. The new package includes:';
			break;

		case 'dar_release_update':
			title = (
				<div className={styles.releaseUpdate}>
					<Icon name="warning" size={18} className={styles.releaseUpdateIcon} />
					Data-at-rest Sensor update is now available
				</div>
			);
			titleText = 'Data-at-rest Sensor update is now available. The new package includes:';
			break;

		case 'dar_release_update_critical':
			title = (
				<div className={styles.releaseUpdate}>
					<Icon name="Danger/FilledRed" size={18} className={styles.releaseUpdateIcon} />
					Data-at-rest Sensor critical update is now available
				</div>
			);
			titleText =
				'A critical update for the data-at-rest sensor is now available. The new package includes:';
			break;

		case 'started_storing_data':
			if (event.database) {
				title = (
					<>
						Database <AssetChip label={event.database.database_name} isExternal={false} noTooltip />{' '}
						started storing data in table{' '}
						<AssetChip label={event.database.table_name} isExternal={false} noTooltip />
					</>
				);
				titleText = `Database ${event.database.database_name} started storing data in table ${event.database.table_name}`;
			} else if (event.kafka) {
				title = (
					<>
						Kafka topic <AssetChip label={event.kafka.topic_name} isExternal={false} noTooltip />{' '}
						started storing data
					</>
				);
				titleText = `Kafka topic ${event.kafka.topic_name} started storing data`;
			} else if (event.s3_bucket) {
				title = (
					<>
						S3 bucket <AssetChip label={event.s3_bucket.name} isExternal={false} noTooltip />{' '}
						started storing data
					</>
				);
				titleText = `S3 bucket ${event.s3_bucket.name} started storing data`;
			} else {
				title = <>{event.object_type} started storing data</>;
				titleText = `${event.object_type} started storing data`;
			}
			break;

		case 'started_storing_datatype':
			if (event.database) {
				title = (
					<>
						Database <AssetChip label={event.database.database_name} isExternal={false} noTooltip />{' '}
						started storing new data type in table{' '}
						<AssetChip label={event.database.table_name} isExternal={false} noTooltip />
					</>
				);
				titleText = `Database ${event.database.database_name} started storing new data type in table ${event.database.table_name}`;
			} else if (event.kafka) {
				title = (
					<>
						Kafka topic <AssetChip label={event.kafka.topic_name} isExternal={false} noTooltip />{' '}
						started storing new data type
					</>
				);
				titleText = `Kafka topic ${event.kafka.topic_name} started storing new data type`;
			} else if (event.s3_bucket) {
				title = (
					<>
						S3 bucket <AssetChip label={event.s3_bucket.name} isExternal={false} noTooltip />{' '}
						started storing new data type
					</>
				);
				titleText = `S3 bucket ${event.s3_bucket.name} started storing new data type`;
			} else {
				title = <>{event.object_type} started storing new data type</>;
				titleText = `${event.object_type} started storing new data type`;
			}
			break;

		default:
			title = <>Unsupported case</>;
			titleText = 'Unsupported case';
	}

	return { title, titleText };
}

function eventTitleAsComponent({
	event,
	size,
	filterIds,
}: {
	event: EventJson;
	size?: string;
	filterIds?: number[];
}) {
	return eventTitle({ event, size, filterIds }).title;
}

function eventTitleAsText(event: EventJson) {
	return eventTitle({ event }).titleText;
}

function EventRow(props: Props) {
	const { event, selected, onSelect, filterIds } = props;

	function onClick() {
		onSelect(selected ? 0 : event.id);
	}

	const formattedLocal = formatWithToday(event.created_at);
	const formattedUTC = dayjs(event.created_at).utc().format(DateFormat.utc);

	const title = eventTitleAsComponent({ event, size: 'small', filterIds });

	return (
		<div
			className={cn(styles.row, { [styles.selected]: selected })}
			onClick={onClick}
			data-test="event-row"
		>
			<div className={cn(styles.flex, styles.topRow)}>
				<Typo variant="D/Medium/Body-S">{title}</Typo>

				<Tooltip title={formattedUTC} className={styles.dateTime}>
					<>{formattedLocal}</>
				</Tooltip>
			</div>

			<div className={cn(styles.flex, styles.bottomRow)}>
				{!isDataTypeEvent(event) &&
					!isPolicyEvent(event) &&
					!isPolicyS3Event(event) &&
					!isExternalAssetConflictEvent(event) &&
					!isDiscoveryCompleteEvent(event) &&
					!isReleaseUpdateEvent(event) && <DataTypesWithMore dataTypes={event.data_types} />}

				{!isDiscoveryCompleteEvent(event) && !isReleaseUpdateEvent(event) && (
					<SensitivityChip sensitivity={event.sensitivity} className={styles.sensitivityChip} />
				)}
			</div>
		</div>
	);
}

export { eventTitleAsComponent, eventTitleAsText };
export default EventRow;
