import { Fragment } from 'react';
import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { EventAsset, ExternalAssetConflictJson } from 'models/eventsV2/dto';
import { getSample } from 'models/samplesV2/api';
import { PATHS } from 'services/router';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import { SampleExistsIcon } from './fields/SampleExistsIcon';
import styles from './index.module.pcss';

type Props = { event: ExternalAssetConflictJson };

function getPathToAsset(asset: { id: EventAsset['id'] }) {
	return generatePath(PATHS.SERVICE_ITEM, { id: asset.id });
}

function ExternalAssetConflictEvent(props: Props) {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const eventRegardingAsset =
		event.event_regarding === 'requestor' ? event.requestor : event.responder;
	const interactsWithAsset =
		event.event_regarding === 'requestor' ? event.responder : event.requestor;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetName =
		eventRegardingAsset === sendingAsset ? (
			<>fallback to {getAssetNameByType(sendingAsset.type, sendingAsset.name)}</>
		) : (
			getAssetNameByType(sendingAsset.type, sendingAsset.name)
		);
	const sendingAssetNameText =
		eventRegardingAsset === sendingAsset
			? `fallback to ${getAssetNameByTypeText(sendingAsset.type, sendingAsset.name)}`
			: getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetName =
		eventRegardingAsset === receivingAsset ? (
			<>fallback to {getAssetNameByType(receivingAsset.type, receivingAsset.name)}</>
		) : (
			getAssetNameByType(receivingAsset.type, receivingAsset.name)
		);
	const receivingAssetNameText =
		eventRegardingAsset === receivingAsset
			? `fallback to ${getAssetNameByTypeText(receivingAsset.type, receivingAsset.name)}`
			: getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);

	const conflictingAssets = event.custom_assets.flatMap((customAsset, i) => {
		return [
			<RouterLink
				inherit
				key={i}
				to={getPathToAsset(customAsset)}
				data-test="event-details-conflict-asset"
			>
				{customAsset.name}
			</RouterLink>,
			<Fragment key={-1 - i}>, </Fragment>,
		];
	});
	conflictingAssets.pop();

	const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Category*: Other
*Conflicting assets*: ${event.custom_assets.map((customAsset) => customAsset.name).join(', ')}
*Third party*: ${event.external_ip}
*User agent*: ${event.user_agent}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Category</span>
				<span>Other</span>

				<span className={styles.detailsLabel}>Conflicting services</span>
				<span>{conflictingAssets}</span>

				<span className={styles.detailsLabel}>Third party</span>
				<span data-test="event-details-ip">{event.external_ip}</span>

				<span className={styles.detailsLabel}>User agent</span>
				<span data-test="event-details-user-agent">{event.user_agent}</span>

				<span className={styles.detailsLabel}>Sending</span>
				<RouterLink inherit to={getPathToAsset(sendingAsset)} data-test="event-details-sending">
					{sendingAssetName}
					{sendingAsset.namespace && (
						<Typo variant="D/Regular/Meta" color="secondary" component="span">
							{' • '}
							{sendingAsset.namespace}
						</Typo>
					)}
				</RouterLink>

				<span className={styles.detailsLabel}>Receiving</span>
				<RouterLink inherit to={getPathToAsset(receivingAsset)} data-test="event-details-receiving">
					{receivingAssetName}
					{receivingAsset.namespace && (
						<Typo variant="D/Regular/Meta" color="secondary" component="span">
							{' • '}
							{receivingAsset.namespace}
						</Typo>
					)}
				</RouterLink>

				<span className={styles.detailsLabel}>Endpoint</span>
				<RouterLink
					inherit
					to={pathToSample}
					data-test="event-details-endpoint"
					state={{
						sample: {
							method: event.method,
							protocol: event.protocol,
							host: event.hostname,
							url: event.url,
						},
						asset: sendingAsset,
					}}
				>
					{event.hostname}
					{event.url}
					<SampleExistsIcon getSampleFn={getSample} sampleId={event.endpoint_id} />
					<br />
					<Typo variant="D/Regular/Meta" color="secondary">
						{methodOrProtocol(event)}
					</Typo>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />

				<RouterLink to={pathToDataMap} data-test="data-map-link">
					<Button size="small" color="tertiary">
						View on data map
					</Button>
				</RouterLink>
			</div>
		</>
	);
}

export default ExternalAssetConflictEvent;
