import { SampleJson } from './dto';

const sampleJsonExample: SampleJson = {
	host: 'fake-host.com',
	url: '/fake/path',
	method: 'GET',
	protocol: 'http',
	asset: 15,
	request: {
		json: '{"a":"*************11111","b":[{}],"c":"1.11","card":"11111111111111","country":"*********","country_code":"**","email":"1*1*11**@*1*1***1.***","first_name":"****","gender":"****","id":1,"ip_address":"111.11.11.111","last_name":"********","password":"************"}',
		data_fields: [
			{
				json_path: '$.card',
				data_type: 2,
				print_value: '"11111111111111"',
				value_type: 'string',
				is_corrected: false,
			},
		],
	},
	response: {
		json: '{"a":"*************11111","b":[{}],"c":"1.11","card":"11111111111111","country":"*********","country_code":"**","email":"1*1*11**@*1*1***1.***","first_name":"****","gender":"****","id":1,"ip_address":"111.11.11.111","last_name":"********","password":"************"}',
		data_fields: [
			{
				json_path: '$.country',
				data_type: 7,
				print_value: '"*********"',
				value_type: 'string',
				is_corrected: true,
			},
		],
	},
	last_seen: 1663768295928,
	response_codes: [200, 403],
	is_encrypted: false,
};

export { sampleJsonExample };
