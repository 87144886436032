import cn from 'classnames';
import { useStore } from 'effector-react';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import { piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { getS3ClusterFiles } from 'models/s3BucketCluster/api';
import { ClusterDirectory, ClusterFile } from 'models/s3BucketCluster/dto';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { toFileSize } from 'services/numbers';
import { FileClusterItem } from '../FileClusterItem';
import parentStyles from '../index.module.css';
import styles from './index.module.css';

function copyToClipboard(data: string) {
	navigator.clipboard.writeText(data).then(() => {
		enqueueSnackbar('Sample path copied');
	});
}

function LongPath({ path }: { path: string }) {
	let visiblePath = path.replace(/\/$/, '');
	let hiddenPath = '';

	if (path.length > 80) {
		for (let i = path.length - 80; i > 0; i--) {
			if (path[i] === '/') {
				visiblePath = path.slice(i);
				hiddenPath = path.slice(0, i);
				break;
			}
		}
	}

	const pathParts = visiblePath.split('/').flatMap((pathPart, i) => [
		<Typo variant="D/SemiBold/Body-S" key={i} component="span">
			{pathPart}
		</Typo>,
		<Typo variant="D/Regular/Body-S" key={-i - 1} component="span" color="secondary">
			{' / '}
		</Typo>,
	]);
	pathParts.pop();

	return (
		<>
			{hiddenPath.length > 0 && (
				<Tooltip title={<Typo variant="D/Regular/Meta">{hiddenPath}</Typo>}>
					<Typo variant="D/SemiBold/Body-S" className={styles.cursor}>
						...
					</Typo>
				</Tooltip>
			)}
			{pathParts}
		</>
	);
}

type Props = {
	cluster: ClusterDirectory;
	bucketId: S3BucketItem['id'];
};

function DirectoryClusterItem(props: Props) {
	const {
		id,
		path,
		real_name_sample,
		size,
		aws_console_url,
		sensitivity,
		data_types,
		last_scan_at,
		clusters,
		has_more_clusters,
	} = props.cluster;

	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [isCollapsed, setCollapsed] = useState(false);

	const [clusterFiles, setClusterFiles] = useState(clusters);
	const [hasMoreData, setHasMoreData] = useState(has_more_clusters);
	const [isLoading, setLoading] = useState(false);
	const [offset, setOffset] = useState(clusters.length);

	const requestRef = useRef(new AbortController());

	useEffect(() => {
		// Clean up old request data / abort
		requestRef.current.abort();
		requestRef.current = new AbortController();

		setClusterFiles(clusters);
		setHasMoreData(has_more_clusters);
		setLoading(false);
		setOffset(clusters.length);

		// Abort up on unmount
		return () => requestRef.current.abort();
	}, [props.cluster]);

	function toggleCollapsed(e: MouseEvent<HTMLElement>) {
		e.stopPropagation(); // prevent GridRow onClick handler
		setCollapsed(!isCollapsed);
	}

	function loadMore() {
		setLoading(true);

		const { dataTypes, nonEmpty } = piiFilterWithGroups;
		const dtParam = dataTypes.length === 0 && nonEmpty ? 'nonempty' : dataTypes.join(',');

		getS3ClusterFiles(
			props.bucketId,
			id,
			{ offset, limit: 100, 'data-types': dtParam },
			requestRef.current.signal
		).then((response) => {
			setClusterFiles(clusters.concat(response.clusters));
			setHasMoreData(response.has_more_clusters);
			setLoading(false);
			setOffset(offset + 100);
		});
	}

	return (
		<>
			<GridRow
				className={parentStyles.rowContainer}
				hover
				border
				data-test="s3-bucket-directory-row"
			>
				<GridCell>
					<ButtonIcon
						icon="ChevronDown/Regular"
						size="S"
						className={cn(!isCollapsed && styles.expanded, styles.toggleButton)}
						onClick={toggleCollapsed}
					/>
					<LongPath path={path} />
				</GridCell>
				<GridCell withoutBlur>
					{!last_scan_at && (
						<Tooltip title="Scanning in progress">
							<Icon
								dataTest="s3-object-scanning"
								name="Clock/Filled"
								size={16}
								className={styles.grayIcon}
							/>
						</Tooltip>
					)}
				</GridCell>
				<GridCell dataTest="s3-bucket-object-type">Directory</GridCell>
				<GridCell align="right">{toFileSize(size)}</GridCell>
				<GridCell dataTest="s3-bucket-sensitivity">
					<SensitivityChip sensitivity={sensitivity} />
				</GridCell>
				<GridCell dataTest="s3-bucket-data-types">
					<PiiTypeList data={data_types} />
				</GridCell>
				<GridCell className={styles.actionsCell}>
					<ButtonIcon
						icon="copyLink"
						size="M"
						onClick={(e: MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							copyToClipboard(real_name_sample);
						}}
					/>
					<a
						href={aws_console_url}
						target="_blank"
						onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
						rel="noopener"
					>
						<ButtonIcon icon="Open/Regular" size="M" />
					</a>
				</GridCell>
			</GridRow>

			{!isCollapsed &&
				clusterFiles.map((fileCluster: ClusterFile) => {
					return (
						<FileClusterItem
							cluster={fileCluster}
							bucketId={props.bucketId}
							isChild
							key={fileCluster.id}
						/>
					);
				})}
			{!isCollapsed && hasMoreData && (
				<LoadMoreButton
					show={hasMoreData}
					loading={isLoading}
					request={loadMore}
					className={styles.loadMoreButton}
				/>
			)}
		</>
	);
}

export { DirectoryClusterItem };
