import { useStore } from 'effector-react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { LayoutContext } from 'layouts/AuthorizedWithLeftMenu';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { PolicyItem as PolicyItemType, PolicyItemWithoutRules } from 'models/policies/dto';
import { getPolicyItemFx } from 'models/policies/effects';
import { policyStore } from 'models/policies/store';
import styles from './index.module.css';
import { LS_POLICY_DUPLICATE_KEY, PolicyItemForm } from './PolicyItemForm';
import { EMPTY_RULE } from './PolicyItemForm/PolicyRules';

const EMPTY_POLICY: PolicyItemType = {
	id: 0,
	data_types: [],
	name: '',
	description: '',
	is_active: true,
	rules: [[EMPTY_RULE]],
	created_at: 0,
	updated_at: 0,
	allow_external_transfer: true,
	allow_unencrypted_s3_buckets: true,
	allow_public_s3_buckets: true,
	allowed_external_assets: [],
};

const duplicatedPolicyName = (policies: PolicyItemWithoutRules[], policyName: string) => {
	let namePart = policyName;
	let number = null;

	const indexCopy = policyName.lastIndexOf('Copy');

	if (indexCopy !== -1) {
		const partCopy = policyName.slice(indexCopy);
		const copyAsArray = partCopy.split(' ');
		namePart = policyName.slice(0, indexCopy - 1);

		if (copyAsArray.length < 2) {
			number = 2;
		} else {
			number = Number(copyAsArray[1]) + 1;
		}
	}

	const policyNamePostfix = `Copy${number ? ` ${number}` : ''}`;

	return `${namePart} ${policyNamePostfix}`;
};

export const PolicyItem = () => {
	const { policies } = useStore(policyStore);
	const [isLoading, setIsLoading] = useState(true);
	const [policy, setPolicy] = useState<PolicyItemType>(EMPTY_POLICY);
	const [policyState, setPolicyState] = useState<'new' | 'edit' | 'duplicate' | 'notExist'>();
	const [duplicatedParsedData, setDuplicatedParsedData] = useState<PolicyItemType | null>(null);
	const { id: policyId }: { id: string } = useParams();
	const layoutBackground = useContext(LayoutContext);

	useEffect(() => {
		layoutBackground.onChange({
			layout: styles.layoutBackground,
			content: styles.contentBorder,
		});

		return () => {
			layoutBackground.reset();
		};
	}, []);

	useEffect(() => {
		if (policyId === 'new') {
			const duplicatedData = localStorage.getItem(LS_POLICY_DUPLICATE_KEY);
			if (duplicatedData) {
				localStorage.removeItem(LS_POLICY_DUPLICATE_KEY);

				try {
					const duplicatedPolicy = JSON.parse(duplicatedData as string);
					setDuplicatedParsedData({
						...duplicatedPolicy,
						id: 0,
						name: duplicatedPolicyName(policies, duplicatedPolicy.name),
					});
				} catch (e) {
					console.warn(`empty ${LS_POLICY_DUPLICATE_KEY}`);
				}
			}

			setPolicyState('new');
			setIsLoading(false);
			return;
		}

		const policyIdNumber = Number(policyId);
		if (Number.isNaN(policyIdNumber)) {
			setPolicyState('notExist');
			setIsLoading(false);
			return;
		}

		getPolicyItemFx(policyIdNumber)
			.then((resp) => {
				setPolicy(resp);
				setPolicyState('edit');
				setIsLoading(false);
			})
			.catch(() => {
				setPolicyState('notExist');
				setIsLoading(false);
			});
	}, [policyId]);

	const query = new URLSearchParams(useLocation().search);
	const dataTypeId = Number(query.get('data_type'));
	const dataTypeIdsNumber = !Number.isNaN(dataTypeId) && dataTypeId !== 0 ? [dataTypeId] : [];

	return (
		<>
			<Header isLoading={isLoading} />

			<Preloader isLoading={isLoading}>
				{policyState === 'new' && (
					<PolicyItemForm
						data={
							duplicatedParsedData === null
								? { ...EMPTY_POLICY, data_types: dataTypeIdsNumber }
								: duplicatedParsedData
						}
					/>
				)}
				{policyState === 'edit' && <PolicyItemForm data={policy} />}
				{policyState === 'notExist' && <div>Policy does not exist</div>}
			</Preloader>
		</>
	);
};
