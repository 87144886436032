import cn from 'classnames';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Badge from 'components/Badge';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { getS3BucketDataFlows } from 'models/s3Buckets/api';
import { S3BucketItem, S3BucketStatus, S3BucketDataFlowsTableItem } from 'models/s3Buckets/dto';
import { getS3BucketStatusFx } from 'models/s3Buckets/effects';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import { MapAsset } from '../../../index';
import { changeMapControls, mapControlsStore, unselectInteractsWith } from '../../model/store';
import commonStyles from '../index.module.css';
import styles from './index.module.css';

type Props = {
	bucket: S3BucketItem;
	asset: MapAsset;
};

function DetailsContent(props: Props) {
	const { asset, bucket } = props;

	const mapControls = useStore(mapControlsStore);
	const gatewaysNamesStore = useStore(gatewaysNamesById);

	const [counters, setCounters] = useState<S3BucketStatus | null>(null);
	const [dataflows, setDataflows] = useState<S3BucketDataFlowsTableItem[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		Promise.all([
			getS3BucketStatusFx({ id: bucket.id, nonEmpty: false }),
			getS3BucketDataFlows(asset.entityId, { limit: 100 }),
		])
			.then(([c, df]) => {
				setCounters(c);
				setDataflows(df.dataflows);
			})
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [bucket.id]);

	function onDataFlowClick(interactsWith: TAssetsItem['id']) {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.selected =
				interactsWith === draft.interactsWith.selected?.id
					? null
					: { id: interactsWith, type: 's3_bucket' };
		});

		changeMapControls(newControls);
	}

	function onDataFlowMouseEnter(interactsWith: TAssetsItem['id']) {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.hovered = { id: interactsWith, type: 's3_bucket' };
		});

		changeMapControls(newControls);
	}

	function onDataFlowMouseLeave() {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.hovered = null;
		});

		changeMapControls(newControls);
	}

	const flowsCount = counters?.asset_flows_count;

	return (
		<div className={commonStyles.detailsContainer}>
			<Preloader isLoading={loading}>
				<div className={commonStyles.scrollContainer}>
					<Accordion
						defaultExpanded={true}
						className={cn(styles.accordion, commonStyles.sidePaddings)}
					>
						<AccordionSummary>
							Connections{' '}
							<Badge
								className={commonStyles.badge}
								badgeContent={typeof flowsCount === 'number' ? toLocaleString(flowsCount) : null}
							/>
						</AccordionSummary>

						<AccordionDetails>
							<GridTable className={commonStyles.table}>
								<GridHead>
									<GridRow className={styles.dataflowRowContainer} border>
										<GridCell>{''}</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Interacts with
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Namespace
										</GridCell>
									</GridRow>
								</GridHead>

								<GridBody className={styles.tableBody}>
									{dataflows.map((dataflow, i) => {
										const { name, type, cluster_id, k8s_types, namespace, interacts_with } =
											dataflow;

										const displayName = getAssetNameByType(type, name);
										const isActive = mapControls.interactsWith.selected?.id === interacts_with;

										return (
											<GridRow
												data-test="s3-bucket-details-row"
												key={i}
												className={styles.dataflowRowContainer}
												onClick={() => onDataFlowClick(interacts_with)}
												border
												hover
												active={isActive}
												onMouseEnter={() => onDataFlowMouseEnter(interacts_with)}
												onMouseLeave={onDataFlowMouseLeave}
											>
												<GridCell withoutBlur>
													{dataflow.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
												</GridCell>

												<GridCell className={styles.gapCell}>
													<Typo variant="D/Medium/Meta">{displayName}</Typo>

													<Typo variant="D/Regular/Meta" color="secondary" className={styles.flex}>
														{getStringDividedByDot(k8s_types, gatewaysNamesStore[cluster_id])}
													</Typo>
												</GridCell>

												<GridCell>
													<Typo variant="D/Regular/Meta">{namespace}</Typo>
												</GridCell>
											</GridRow>
										);
									})}
								</GridBody>

								<Button
									color="tertiary"
									size="small"
									endIcon={<Icon name="Open/Regular" size={20} />}
									fullWidth
									href={generatePath(PATHS.S3_BUCKETS_ITEM, { id: bucket.id, tab: 'connections' })}
									target="_blank"
									className={styles.detailedInfo}
								>
									View detailed info
								</Button>
							</GridTable>
						</AccordionDetails>
					</Accordion>
				</div>
			</Preloader>
		</div>
	);
}

export default DetailsContent;
