import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import NoContent from 'components/NoContent';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { AccountAWSItem } from 'models/sensors/aws/dto';
import { getAccountsAWSFx } from 'models/sensors/aws/effects';
import { accountsAWSList } from 'models/sensors/aws/store';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import { SensorsWrapper } from '../..';
import { CompletedItem } from './CompletedItem';
import completedStyles from './CompletedItem/index.module.css';
import { DraftItem } from './DraftItem';
import draftStyles from './DraftItem/index.module.css';
import styles from './index.module.css';
import { NoAccountsBlock } from './NoAccountBlock';

export function AccountList() {
	const accountsFromStore = useStore(accountsAWSList);
	const [isLoading, setLoading] = useState(true);

	useEffect(function onInit() {
		getAccountsAWSFx().finally(function finalizeRequest() {
			setLoading(false);
		});
	}, []); // Fetch sensors from server, once.

	// @ts-ignore TODO: SMAT-3957 update ts to 5.4.5
	const accounts: { completed: AccountAWSItem[]; draft: AccountAWSItem[] } = Object.groupBy(
		accountsFromStore,
		({ role_arn }: AccountAWSItem) => (role_arn ? 'completed' : 'draft')
	);
	const draftAccountsExist = accounts.draft && accounts.draft.length !== 0;
	const completedAccountsExist = accounts.completed && accounts.completed.length !== 0;
	const isContent = completedAccountsExist || draftAccountsExist;

	return (
		<SensorsWrapper>
			<div className={styles.container}>
				<Preloader isLoading={isLoading}>
					<GridTable dataTest="aws-accounts-table-draft" className={styles.table}>
						<GridBody>
							{draftAccountsExist && (
								<>
									<Typo variant="D/Medium/H100-Header" className={styles.header}>
										Draft
									</Typo>

									<GridHead>
										<GridRow head className={draftStyles.rowContainer}>
											<GridCell head>AWS external ID</GridCell>

											<GridCell head>{''}</GridCell>
										</GridRow>
									</GridHead>

									{accounts.draft?.map(function renderDraftAccounts(account) {
										return <DraftItem account={account} key={account.id} />;
									})}
								</>
							)}

							{completedAccountsExist && (
								<>
									{draftAccountsExist && completedAccountsExist && (
										<Typo
											variant="D/Medium/H100-Header"
											className={cn(styles.header, styles.secondHeader)}
										>
											Connections
										</Typo>
									)}

									<GridHead>
										<GridRow head className={completedStyles.rowContainer}>
											<GridCell head>Role ARN</GridCell>

											<GridCell head>Last Checked</GridCell>
										</GridRow>
									</GridHead>

									{accounts.completed?.map(function renderCompletedAccounts(account) {
										return <CompletedItem account={account} key={account.id} />;
									})}
								</>
							)}

							{!isContent && (
								<>
									<GridHead>
										<GridRow head className={completedStyles.rowContainer}>
											<GridCell head>Role ARN</GridCell>

											<GridCell head>Last Checked</GridCell>
										</GridRow>
									</GridHead>

									<NoContent type="awsAccount" className={completedStyles.rowContainer}>
										<NoAccountsBlock />
									</NoContent>
								</>
							)}
						</GridBody>
					</GridTable>
				</Preloader>

				{isContent && <NeedHelpBlock />}
			</div>
		</SensorsWrapper>
	);
}
