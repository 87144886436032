import { MouseEvent } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { ExternalLinkButton } from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import { ClusterFile, fileTypeLabels } from 'models/s3BucketCluster/dto';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { toFileSize, toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN, pluralize } from 'services/strings';
import parentStyles from '../index.module.css';
import styles from './index.module.css';

function copyToClipboard(data: string) {
	navigator.clipboard.writeText(data).then(() => {
		enqueueSnackbar('Sample path copied');
	});
}

type Props = {
	cluster: ClusterFile;
	bucketId: S3BucketItem['id'];
	isChild?: boolean;
};

function AnalyzableItem(props: Props) {
	const {
		id,
		name,
		type,
		real_name_sample,
		objects_count,
		size,
		aws_console_url,
		sensitivity,
		data_types,
		last_scan_at,
	} = props.cluster;

	const history = useHistory();

	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];

	const count = objects_count - 1; // because `[THIS ONE] + (n-1) similar files`

	function clickHandler() {
		const path = generatePath(PATHS.S3_CLUSTER_ITEM, { id: props.bucketId, clusterId: id });
		history.push(path);
	}

	return (
		<>
			<GridRow
				className={parentStyles.rowContainer}
				level={props.isChild ? 2 : 1}
				onClick={clickHandler}
				hover
				border
				data-test="s3-bucket-object-row"
			>
				<GridCell>
					<div className={styles.nameContainer}>
						<Typo variant={props.isChild ? 'D/Medium/Body-S' : 'D/SemiBold/Body-S'}>{name}</Typo>
						{count > 0 && (
							<Typo variant="D/Regular/Meta">
								+ {toLocaleString(count)} similar {pluralize('file', count)}
							</Typo>
						)}
					</div>
				</GridCell>

				<GridCell withoutBlur>
					{!last_scan_at && (
						<Tooltip title="Scanning in progress">
							<Icon
								dataTest="s3-object-scanning"
								name="Clock/Filled"
								size={16}
								className={styles.grayIcon}
							/>
						</Tooltip>
					)}
				</GridCell>

				<GridCell dataTest="s3-bucket-object-type">{typeLabel}</GridCell>

				<GridCell align="right">{toFileSize(size)}</GridCell>

				<GridCell dataTest="s3-bucket-sensitivity">
					<SensitivityChip sensitivity={sensitivity} />
				</GridCell>

				<GridCell dataTest="s3-bucket-data-types">
					<PiiTypeList data={data_types} />
				</GridCell>

				<GridCell className={styles.actionsCell}>
					<ButtonIcon
						icon="copyLink"
						size="M"
						onClick={(e: MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							copyToClipboard(real_name_sample);
						}}
					/>

					<ExternalLinkButton href={aws_console_url} />
				</GridCell>
			</GridRow>
		</>
	);
}

function NotAnalyzableItem(props: Props) {
	const { type, real_name_sample, objects_count, size, aws_console_url } = props.cluster;

	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];
	const labelForFirstCell = type === 'other' ? 'other' : typeLabel.toLocaleLowerCase();

	return (
		<>
			<GridRow
				className={parentStyles.rowContainer}
				border
				level={props.isChild ? 2 : 1}
				data-test="s3-bucket-object-row"
			>
				<GridCell className={styles.pathCell}>
					<Typo variant={props.isChild ? 'D/Medium/Body-S' : 'D/SemiBold/Body-S'} color="secondary">
						{toLocaleString(objects_count)} {labelForFirstCell} {pluralize('file', objects_count)}
					</Typo>
				</GridCell>

				<GridCell
					className={styles.iconCell}
					withoutBlur
					dataTest="s3-bucket-object-scan-unsupported"
				>
					<Tooltip title="Scanning not supported">
						<Icon name="Prohibited/Regular" size={16} className={styles.grayIcon} />
					</Tooltip>
				</GridCell>

				<GridCell dataTest="s3-bucket-object-type">
					<Typo variant="D/Regular/Body-S" color="secondary">
						{typeLabel}
					</Typo>
				</GridCell>

				<GridCell align="right">
					<Typo variant="D/Regular/Body-S" color="secondary">
						{toFileSize(size)}
					</Typo>
				</GridCell>

				<GridCell>
					<SensitivityChip sensitivity="N/A" />
				</GridCell>

				<GridCell>
					<Typo variant="D/Regular/Body-S" color="secondary">
						{DASH_SIGN}
					</Typo>
				</GridCell>

				<GridCell className={styles.actionsCell}>
					<ButtonIcon icon="copyLink" size="M" onClick={() => copyToClipboard(real_name_sample)} />

					<ExternalLinkButton href={aws_console_url} />
				</GridCell>
			</GridRow>
		</>
	);
}

function FileClusterItem(props: Props) {
	return props.cluster.is_analyzable ? (
		<AnalyzableItem {...props} />
	) : (
		<NotAnalyzableItem {...props} />
	);
}

export { FileClusterItem };
