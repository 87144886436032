import { get } from 'api/Api';
import { AssetJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { assetsMap } from 'models/assets/store';
import checkResponse from 'services/api/checkResponse';
import {
	DataFlowDetailsJson,
	DataFlowDetailsParams,
	DataFlowDetailsQueryParam,
	DataFlowDetailsTableItem,
} from './dto';
import { dataFlowDetailsJsonExample } from './examples';

async function getDataFlowDetailsV2({
	asset1Id,
	asset2Id,
	dataTypes,
	flowDirection,
	streamDirection,
	offset,
	limit,
}: DataFlowDetailsParams) {
	const queryParam: DataFlowDetailsQueryParam = {
		limit,
		offset,
		direction: streamDirection,
	};

	// If there's no data types, we don't send 'data-types' query param
	if (dataTypes.length > 0) {
		queryParam['data-types'] = String(dataTypes);
	}

	return get<DataFlowDetailsJson>(
		`v2/assets/${asset1Id}/dataflows/${asset2Id}/${flowDirection}`,
		queryParam
	)
		.then(checkResponse(dataFlowDetailsJsonExample))
		.then(async (data) => {
			let assets = assetsMap.getState();
			const checkAssets = assets[asset1Id] && assets[asset2Id];

			if (!checkAssets) {
				await getAssetsFx();
				assets = assetsMap.getState();
			}

			const asset1 = assets[asset1Id];
			const asset2 = assets[asset2Id];

			return {
				...data,
				asset1_data: asset1,
				asset2_data: asset2,
				flow_direction: flowDirection,
				streams: data.streams
					.map((stream): DataFlowDetailsTableItem => {
						const assetIsRequestor =
							(flowDirection === 'incoming' && stream.direction === 'response') ||
							(flowDirection === 'outgoing' && stream.direction === 'request');
						const responder: AssetJson = assetIsRequestor ? asset2 : asset1;

						return {
							...stream,
							asset_name: responder['name'],
							asset_type: responder['type'],
							asset_k8s_types: responder['k8s_types'],
							asset_namespace: responder['namespace'],
							is_encrypted: stream.is_encrypted,
						};
					})
					// Our limit param higher for 1 for checking is there more data or not.
					// That's why we send data less for 1 than limit
					.slice(0, limit - 1),
				more_data: data.streams.length === limit,
			};
		});
}

export { getDataFlowDetailsV2 };
