import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { ClusterGeoLocationItem } from 'models/clusterGeoLocations/dto.ts';
import { clusterGeoLocationsByTokenId } from 'models/clusterGeoLocations/store.ts';
import { GatewayJson } from 'models/gateways/dto';
import { updateGatewayNameFx } from 'models/gateways/effects';
import { APIError } from 'services/api/httpRequest';
import { EditableInput } from 'views/common/EditableInput';
import styles from './index.module.css';

const descriptions = {
	ok: 'Connected and working',
	error: "Proxy is down or can't connect",
};

const NOT_AVAILABLE = 'N/A';
const agentVersions = (
	versions: GatewayJson['versions'],
	clusterGeoLocation: ClusterGeoLocationItem
) => (
	<div className={styles.agentVersions}>
		<Typo variant="D/Regular/Meta">Digger: {versions.digger || NOT_AVAILABLE}</Typo>
		<Typo variant="D/Regular/Meta">Interceptor: {versions.interceptor || NOT_AVAILABLE}</Typo>
		<Typo variant="D/Regular/Meta">Detector: {versions.detectionTool || NOT_AVAILABLE}</Typo>
		<hr />
		<Typo variant="D/Regular/Meta">IP: {clusterGeoLocation.ip}</Typo>
		<Typo variant="D/Regular/Meta">AWS region: {clusterGeoLocation.aws_region}</Typo>
		<Typo variant="D/Regular/Meta">Country: {clusterGeoLocation.country_iso_name}</Typo>
	</div>
);

type Props = {
	gateway: GatewayJson;
};

function GatewayName(props: Props) {
	const { gateway } = props;

	const clusterGeoLocationsMap = useStore(clusterGeoLocationsByTokenId);
	const clusterGeoLocation = clusterGeoLocationsMap[gateway.id] || {};

	const description = gateway.text || descriptions[gateway.status];

	async function saveGatewayName(newName: string): Promise<null | string> {
		if (!newName) return 'Name can not be empty';

		const payload = { ...gateway, name: newName };

		try {
			await updateGatewayNameFx(payload);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			enqueueSnackbar('Something went wrong');
			throw error;
		}

		return null;
	}

	const agentVersionsNode = useMemo(
		() => agentVersions(gateway.versions, clusterGeoLocation),
		[gateway.versions]
	);

	return (
		<div className={styles.container}>
			<EditableInput
				value={gateway.name}
				onSave={saveGatewayName}
				className={styles.editableInput}
			/>

			{gateway.status && (
				<Typo variant="D/Regular/Meta" color="secondary">
					<Tooltip title={agentVersionsNode} placement="top" interactive={true}>
						{description}
					</Tooltip>
				</Typo>
			)}
		</div>
	);
}

export default GatewayName;
