import { useMemo } from 'react';
import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { AssetGroupListItem } from 'models/assetsGroups/dto';
import { DateFormat, format } from 'services/dayjs';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import commonStyles from '../index.module.css';
import Label from '../Label';
import styles from './index.module.pcss';

type Props = {
	group: AssetGroupListItem;
};

function Summary(props: Props) {
	const { group } = props;

	const createdAt = useMemo(() => format(group.created_at, DateFormat.date), [group.created_at]);

	return (
		<div className={commonStyles.sidePaddings}>
			<div className={commonStyles.detailsGrid}>
				<Label title="Object type">Group</Label>

				<Label title="Description">{group.description || DASH_SIGN}</Label>

				<Label title="Owner">{group.owner || DASH_SIGN}</Label>

				<Label title="Created">{createdAt}</Label>
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={generatePath(PATHS.ASSET_GROUP_ITEM, { id: group.id })}
				target="_blank"
				className={styles.detailedInfo}
			>
				View detailed info
			</Button>
		</div>
	);
}

export default Summary;
