import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { getDatabaseTableSample } from 'models/databases/api';
import { DatabaseTableSample } from 'models/databases/dto';
import { databaseInstancesById } from 'models/databases/store';
import { CSVViewer } from 'views/common/SampleViewer/CSVViewer';
import { DatabaseSampleHeader } from './DatabaseSampleHeader';
import styles from './index.module.css';
import { WashedSampleProps, WashedSample } from './WashedSample';

export function DatabaseSampleViewer() {
	const history = useHistory();
	const [sample, setSample] = useState<DatabaseTableSample | null>(null);
	const [isLoading, setLoading] = useState(true);
	const databaseInstances = useStore(databaseInstancesById);

	const instance = useMemo(
		() => (sample ? databaseInstances[sample.instance_id] : null),
		[sample?.instance_id, databaseInstances]
	);

	const params: {
		sampleId: string;
	} = useParams();

	useEffect(() => {
		getDatabaseTableSample(+params.sampleId)
			.then((response) => {
				setSample(response);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<Preloader isLoading={isLoading}>
			{sample ? (
				<>
					<DatabaseSampleHeader sample={sample} instance={instance} />

					<div className={styles.wrapper}>
						<CSVViewer {...sample} database />
					</div>
				</>
			) : (
				<WashedSample
					id={params.sampleId}
					state={history.location.state as WashedSampleProps['state']}
				/>
			)}
		</Preloader>
	);
}
