import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { dataTypesById } from 'models/dataTypes/store';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { AssetEventJson } from 'models/eventsV2/dto';
import { getSample } from 'models/samplesV2/api';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import Asset from './fields/Asset';
import { SampleExistsIcon } from './fields/SampleExistsIcon';
import styles from './index.module.pcss';

type Props = { event: AssetEventJson };

function AssetEvent(props: Props) {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);
	const eventRegardingAsset =
		event.event_regarding === 'requestor' ? event.requestor : event.responder;
	const interactsWithAsset =
		event.event_regarding === 'requestor' ? event.responder : event.requestor;

	const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data flow change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Sensitivity</span>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<span className={styles.detailsLabel}>Category</span>
				<span>Data flow change</span>

				<span className={styles.detailsLabel}>Data types</span>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<span className={styles.detailsLabel}>Sending</span>
				<Asset asset={sendingAsset} dataTest="event-details-sending" />

				<span className={styles.detailsLabel}>Receiving</span>
				<Asset asset={receivingAsset} dataTest="event-details-receiving" />

				<span className={styles.detailsLabel}>Endpoint</span>
				<RouterLink
					inherit
					to={pathToSample}
					data-test="event-details-endpoint"
					state={{
						sample: {
							method: event.method,
							protocol: event.protocol,
							host: event.hostname,
							url: event.url,
						},
						asset: sendingAsset,
					}}
				>
					{event.hostname}
					{event.url}
					<SampleExistsIcon getSampleFn={getSample} sampleId={event.endpoint_id} />
					<br />
					<Typo variant="D/Regular/Meta" color="secondary">
						{methodOrProtocol(event)}
					</Typo>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />

				<Button
					size="small"
					color="tertiary"
					endIcon={<Icon name="Open/Regular" size={20} />}
					href={pathToEvidence}
					target="_blank"
				>
					View sample
				</Button>

				<RouterLink to={pathToDataMap} data-test="data-map-link">
					<Button size="small" color="tertiary">
						View on data map
					</Button>
				</RouterLink>
			</div>
		</>
	);
}

export default AssetEvent;
