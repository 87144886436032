import Papa from 'papaparse';
import { S3Sample } from 'models/s3Sample/dto';
import { CSVSampleColumn } from 'models/sample/dto';
import { DASH_SIGN } from 'services/strings';

type CSVAsObject = { [key: string]: string };

/* From																To
{																			{
	"data_type": 5,												"column_index": 6,
	"locator_column": 6,									"column_name": "Passport",
	"locator_line": 1,										"data_fields": [
	"locator_path": "Passport"							{
}																						"data_type": 5,
																						"value": "111-111-1111"
																					}
																				"value": "111-111-1111"
																			}
*/
function getColumns(sample: S3Sample['sample'], data_fields: S3Sample['data_fields']) {
	const parsedCSV = Papa.parse(sample.trim(), { header: true });
	const fields = parsedCSV.meta.fields || [];
	const values = (parsedCSV.data || []) as CSVAsObject[];
	const result: CSVSampleColumn[] = [];

	fields.forEach((fieldName, fieldIndex) => {
		const column: CSVSampleColumn = {
			column_index: fieldIndex,
			column_name: fieldName,
			data_fields: [],
			value: DASH_SIGN, // CSV may consist of just headers
		};

		// If CSV has value lines, then the column takes the value from the first one
		if (values[0]) {
			column.value = values[0][fieldName];
		}

		for (let i = 0; i < data_fields.length; i++) {
			const dataField = data_fields[i];

			if (fieldName !== data_fields[i].locator_path) {
				continue;
			}

			if (dataField.data_type !== 0 && values[dataField.locator_line - 1]) {
				column.data_fields.push({
					data_type: dataField.data_type,
					value: values[dataField.locator_line - 1][fieldName],
				});
			}
		}

		result.push(column);
	});

	return result;
}

export { getColumns };
