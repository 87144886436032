import { useStore } from 'effector-react';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import Badge from 'components/Badge';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { TabLink, Tabs, useTabLink } from 'components/Tabs';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getClusterGeoLocationsFx } from 'models/clusterGeoLocations/effects.ts';
import { getGatewaysFx } from 'models/gateways/effects';
import { gatewaysList } from 'models/gateways/store';
import { getReleaseFx } from 'models/release/effects';
import { getAccountsAWSFx } from 'models/sensors/aws/effects';
import { accountsAWSList } from 'models/sensors/aws/store';
import { getSensorsDARFx } from 'models/sensors/dar/effects';
import { sensorsDARList } from 'models/sensors/dar/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { CreateButton } from './AWS/AccountList/CreateButton';
import styles from './index.module.css';

const tabs = [PATHS.SENSORS_DIM, PATHS.SENSORS_DAR, PATHS.ACCOUNT_AWS];

type Props = {
	children: ReactNode;
};

const finalStepLabels = {
	[PATHS.SENSORS_DIM]: 'Data-in-motion Sensors',
	[PATHS.SENSORS_DAR]: 'Data-at-rest Sensors',
	[PATHS.ACCOUNT_AWS]: 'AWS accounts',
};

function SensorsWrapper(props: Props) {
	const { children } = props;

	const location = useLocation();
	const currentTab = useTabLink(tabs);

	const sensorsDIM = useStore(gatewaysList).filter((sensor) => !sensor.is_deleted);
	const sensorsDAR = useStore(sensorsDARList).filter((sensor) => !sensor.is_deleted);
	const accountsAWS = useStore(accountsAWSList);

	const isDIM = currentTab === PATHS.SENSORS_DIM;
	const isAccountAWS = currentTab === PATHS.ACCOUNT_AWS;

	let isContent = false;
	switch (true) {
		case isAccountAWS:
			isContent = accountsAWS.length !== 0;
			break;
		case isDIM:
			isContent = sensorsDIM.length !== 0;
			break;
		default:
			isContent = sensorsDAR.length !== 0;
	}

	useEffect(() => {
		getGatewaysFx();
		getSensorsDARFx();
		getReleaseFx();
		getClusterGeoLocationsFx();
		getAccountsAWSFx();
	}, [location]);

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: [{ path: PATHS.SENSORS, name: 'Infrastructure access' }],
					finalStep: finalStepLabels[currentTab || PATHS.SENSORS_DIM],
				}}
				titleBlock={
					<>
						<Typo variant="D/Medium/H400-Page-Title">Infrastructure access</Typo>

						{isContent &&
							(isAccountAWS ? (
								<CreateButton size="small" />
							) : (
								<RouterLink to={isDIM ? PATHS.SENSORS_DIM_CREATE : PATHS.SENSORS_DAR_CREATE}>
									<Button
										size="small"
										data-test="create-gateway-bt"
										startIcon={<Icon name="Add/Regular" size={20} />}
									>
										New Sensor
									</Button>
								</RouterLink>
							))}
					</>
				}
			/>

			<Tabs value={currentTab}>
				<TabLink
					label={
						<>
							Data-in-motion Sensors{' '}
							<Badge
								badgeContent={toLocaleString(sensorsDIM.length)}
								data-test="dim-sensor-counter"
							/>
						</>
					}
					value={PATHS.SENSORS_DIM}
				/>

				<TabLink
					label={
						<>
							Data-at-rest Sensors{' '}
							<Badge
								badgeContent={toLocaleString(sensorsDAR.length)}
								data-test="dar-sensor-counter"
							/>
						</>
					}
					value={PATHS.SENSORS_DAR}
				/>

				<TabLink
					label={
						<>
							AWS connections{' '}
							<Badge
								badgeContent={toLocaleString(accountsAWS.length)}
								data-test="aws-connection-counter"
							/>
						</>
					}
					value={PATHS.ACCOUNT_AWS}
				/>
			</Tabs>

			<div className={styles.content}>{children}</div>
		</>
	);
}

export { SensorsWrapper };
