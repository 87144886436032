import { TAssetsItem } from 'models/assetsExtended/dto';
import { EndpointsItem } from 'models/endpointsV2/dto';

interface IEmptyData {
	endpoints: EndpointsItem[];
	assets: TAssetsItem[];
}

const emptyData: IEmptyData = {
	/*
	hosts: [
		{
			groups: [79, 91, 92, 187, 261],
			host: 'test-load-otyzo.com',
			last_date: 1652835576000,
			pii_types: ['BIRTH_DATE', 'EMAIL', 'PHONE', 'GENDER', 'PERSON'],
			sensitivity: 'High',
			total_apis: 28,
			total_requests: 28,
		},
		{
			groups: [79, 91, 92, 187, 261],
			host: 'test-load-otyzn.com',
			last_date: 1652889399000,
			pii_types: ['BIRTH_DATE', 'EMAIL', 'PHONE', 'GENDER', 'PERSON'],
			sensitivity: 'High',
			total_apis: 64,
			total_requests: 70,
		},
		{
			groups: [79, 91, 92, 187, 261],
			host: 'test-load-otyzm.com',
			last_date: 1652901698000,
			pii_types: ['BIRTH_DATE', 'EMAIL', 'PHONE', 'GENDER', 'PERSON'],
			sensitivity: 'High',
			total_apis: 71,
			total_requests: 72,
		},
		{
			groups: [79, 91, 92, 187, 261],
			host: 'test-load-otyzc.com',
			last_date: 1652898081000,
			pii_types: ['BIRTH_DATE', 'EMAIL', 'PHONE', 'GENDER', 'PERSON'],
			sensitivity: 'High',
			total_apis: 23,
			total_requests: 23,
		},
		{
			groups: [79, 91, 92, 187, 261],
			host: 'test-load-otyyo.com',
			last_date: 1652898318000,
			pii_types: ['BIRTH_DATE', 'EMAIL', 'PHONE', 'GENDER', 'PERSON'],
			sensitivity: 'High',
			total_apis: 44,
			total_requests: 45,
		},
	],
	*/
	endpoints: [
		{
			host: 'test-load-odixo.com',
			id: 3843173,
			last_seen: 1652880276000,
			method: 'POST',
			protocol: 'http',
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			url: '/mjq0o',
			asset: 25,
			is_encrypted: false,
		},
		{
			host: 'test-load-mji5m.com',
			id: 3842949,
			last_seen: 1652891597000,
			method: 'POST',
			protocol: 'http',
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			url: '/oda1m',
			asset: 25,
			is_encrypted: false,
		},
		{
			host: 'test-load-mjcyo.com',
			id: 3839509,
			last_seen: 1652875218000,
			method: 'POST',
			protocol: 'http',
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			url: '/mze0m',
			asset: 25,
			is_encrypted: false,
		},
		{
			host: 'test-load-mtkwm.com',
			id: 3838584,
			last_seen: 1652880077000,
			method: 'POST',
			protocol: 'http',
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			url: '/mzc0m',
			asset: 25,
			is_encrypted: false,
		},
		{
			host: 'test-load-mjqzn.com',
			id: 3837892,
			last_seen: 1652862869000,
			method: 'POST',
			protocol: 'http',
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			url: '/mjcwo',
			asset: 25,
			is_encrypted: false,
		},
	],
	assets: [
		{
			cluster_id: 1,
			id: 3,
			name: 'other_in',
			namespace: 'development',
			is_external: true,
			is_deleted: false,
			type: 'other_in',
			k8s_types: [],
			endpoints: 10,
			interacts_with: 20,
			data_types: [1, 2, 3, 4, 5],
			sensitivity: 'High',
			last_seen: 1662994564638,
			groups: [],
		},
		{
			cluster_id: 1,
			id: 11,
			name: 'nginx-controller',
			namespace: 'stage',
			is_external: false,
			is_deleted: false,
			type: 'internal',
			k8s_types: ['ClusterIP', 'LoadBalancer'],
			endpoints: 10,
			interacts_with: 20,
			data_types: [1, 12, 6],
			sensitivity: 'Low',
			last_seen: 1662994564338,
			groups: [],
		},
		{
			cluster_id: 1,
			id: 12,
			name: 'api-backend',
			namespace: 'development',
			is_external: false,
			is_deleted: false,
			type: 'internal',
			k8s_types: ['ClusterIP'],
			endpoints: 10,
			interacts_with: 20,
			data_types: [3],
			sensitivity: 'Medium',
			last_seen: 1662994560038,
			groups: [],
		},
		{
			cluster_id: 1,
			id: 13,
			name: 'crypto-node',
			namespace: '',
			is_external: false,
			is_deleted: false,
			type: 'internal',
			k8s_types: [],
			endpoints: 10,
			interacts_with: 20,
			data_types: [3],
			sensitivity: 'Medium',
			last_seen: 1662994560038,
			groups: [],
		},
		{
			cluster_id: 1,
			id: 25,
			name: 'auth-service-stage',
			namespace: 'production',
			is_external: false,
			is_deleted: false,
			type: 'internal',
			k8s_types: ['ClusterIP'],
			endpoints: 10,
			interacts_with: 20,
			data_types: [3],
			sensitivity: 'Medium',
			last_seen: 1662994560038,
			groups: [],
		},
	],
};

export default emptyData;
