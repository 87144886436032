import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import Typo from 'components/typography/Typo';
import { ReleaseJson } from 'models/release/dto';
import { releaseStore } from 'models/release/store';
import { ReleaseUpdateMessage } from 'views/common/ReleaseUpdateMessage';
import styles from './index.module.css';

const STORAGE_KEY_DIM = 'dim-hidden-notification-date';
const STORAGE_KEY_DAR = 'dar-hidden-notification-date';

const isReleaseNotificationHidden = (
	currentRelease: ReleaseJson,
	type: 'dim' | 'dar',
	diff: number
) => {
	if (type === 'dim') {
		const hideNotificationDate = Number(localStorage.getItem(STORAGE_KEY_DIM));

		return (
			currentRelease.dim.last_event_date <= 0 ||
			currentRelease.dim.last_event_date < hideNotificationDate ||
			diff <= 0
		);
	} else {
		const hideNotificationDate = Number(localStorage.getItem(STORAGE_KEY_DAR));

		return (
			currentRelease.dar.last_event_date <= 0 ||
			currentRelease.dar.last_event_date < hideNotificationDate ||
			diff <= 0
		);
	}
};

const hideReleaseNotification = (type: 'dim' | 'dar') => {
	localStorage.setItem(
		type === 'dim' ? STORAGE_KEY_DIM : STORAGE_KEY_DAR,
		String(new Date().getTime())
	);
};

const ReleaseUpdateAlert = ({ type }: { type: 'dim' | 'dar' }) => {
	const release = useStore(releaseStore);
	const diff = release[type].diff_with_actual_release;
	const [hide, setHide] = useState(true);
	const [showDetails, setShowDetails] = useState(false);

	useEffect(() => {
		setHide(isReleaseNotificationHidden(release, type, diff));
	}, [release, type, diff]);

	if (hide) return null;

	const alertType = diff === 1 ? AlertSeverity.warning : AlertSeverity.error;

	return (
		<Alert
			onClick={() => {
				hideReleaseNotification(type);
				setHide(true);
			}}
			severity={alertType}
			header={
				<>
					{type === 'dim' &&
						alertType === AlertSeverity.warning &&
						'Data-in-motion Sensor update is now available'}
					{type === 'dim' &&
						alertType === AlertSeverity.error &&
						'Data-in-motion Sensor critical update is now available'}

					{type === 'dar' &&
						alertType === AlertSeverity.warning &&
						'Data-at-rest Sensor update is now available'}
					{type === 'dar' &&
						alertType === AlertSeverity.error &&
						'Data-at-rest Sensor critical update is now available'}
				</>
			}
			className={styles.alert}
		>
			{showDetails ? (
				<>
					<ReleaseUpdateMessage type={type} critical={alertType === AlertSeverity.error} />
					<Typo
						variant="D/Medium/Body-S"
						onClick={() => setShowDetails(false)}
						className={cn(styles.showDetails, styles.showLess)}
					>
						Show less
					</Typo>
				</>
			) : (
				<Typo
					variant="D/Medium/Body-S"
					onClick={() => setShowDetails(true)}
					className={cn(styles.showDetails, styles.showMore)}
				>
					Show more
				</Typo>
			)}
		</Alert>
	);
};

export { ReleaseUpdateAlert };
