import { createStore } from 'effector';
import dropSession from 'services/dropSession';
import { Client, Session } from './dto';
import { getSessionFx, signInFx, signUpFx, signUpWithTokenFx, restorePasswordFx } from './effects';

interface State {
	isAuthorized: boolean;
	client?: Client;
}

const initialState: State = {
	isAuthorized: false,
};

function setSession(state: State, session: Session): State {
	return { ...state, ...session, isAuthorized: true };
}

function clearSession(state: State): State {
	return { ...state, client: undefined, isAuthorized: false };
}

const sessionStore = createStore<State>(initialState);

sessionStore
	.on(signInFx.doneData, setSession)
	.on(signUpFx.doneData, setSession)
	.on(restorePasswordFx.doneData, setSession)
	.on(signUpWithTokenFx.doneData, setSession)
	.on(getSessionFx.doneData, setSession)
	.on(dropSession, clearSession);

const isAuthorizedStore = sessionStore.map(({ isAuthorized }) => isAuthorized);

// Use this on auth routes only(pass check for undefined!)
const clientStore = sessionStore.map(({ client }) => client!);

export { isAuthorizedStore, clientStore };
