import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import commonStyles from '../index.module.css';
import Label from '../Label';
import styles from './index.module.pcss';

type Props = {
	kafkaInstance: KafkaInstanceItem;
};

function Summary({ kafkaInstance }: Props) {
	return (
		<div className={commonStyles.sidePaddings}>
			<div className={commonStyles.detailsGrid}>
				<Label data-test="kafka-details-object-type" title="Object type">
					Kafka cluster
				</Label>

				<Label title="Description">{kafkaInstance.description || DASH_SIGN}</Label>

				<Label title="Owner">{kafkaInstance.owner || DASH_SIGN}</Label>

				<Label title="Messages">{toLocaleString(kafkaInstance.messages_count)}</Label>

				<Label data-test="kafka-details-topics" title="Topics">
					{toLocaleString(kafkaInstance.topics_count)}
				</Label>

				<Label title="sensitivity">
					<SensitivityChip
						data-test="kafka-details-sensitivity"
						sensitivity={kafkaInstance.sensitivity}
					/>
				</Label>

				<Label title="data types">
					<PiiTypeList
						dataTest="kafka-details-data-types"
						noHighlight
						data={kafkaInstance.data_types}
					/>
				</Label>
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={generatePath(PATHS.KAFKA_INSTANCES_ITEM, { id: kafkaInstance.id })}
				target="_blank"
				className={styles.detailedInfo}
			>
				View detailed info
			</Button>
		</div>
	);
}

export default Summary;
