import { DataFlowDetailsJson } from './dto';

const dataFlowDetailsJsonExample: DataFlowDetailsJson = {
	streams: [
		{
			direction: 'request',
			endpoint_id: 1010,
			host: 'analytics.acme.com',
			url: '/api/v1/orders/',
			method: 'GET',
			protocol: 'http',
			data_types: [1, 5],
			last_seen: 1653636900042,
			src_asset: 3679106,
			dst_asset: 3691119,
			is_encrypted: false,
		},
	],
	data_types: [1, 5],
};

export { dataFlowDetailsJsonExample };
