import { SensorDARItem, SensorDARList } from './dto';

const offlineSensorJsonExample: SensorDARItem = {
	id: 1,
	name: 'New sensor (1)',
	region: 'us-west-1',
	token: '00000000-0000-0000-0000-000000000000',
	status: 'error',
	text: 'Last message from Crawler at 2023-08-25 15:11:05 UTC',
	versions: {
		crawler: 'v1.2.3',
		detectionTool: 'v7.8.9',
	},
	created_at: 1698351687739,
	updated_at: 1698351687739,
	is_deleted: false,
};

const onlineSensorJsonExample: SensorDARItem = {
	id: 10,
	name: 's3 sensor',
	region: '',
	token: '00000000-0000-0000-0000-000000000000',
	status: 'ok',
	// no 'text' field is returned for online sensors
	versions: {
		crawler: 'v1.2.3',
		detectionTool: 'v7.8.9',
	},
	created_at: 1698351687739,
	updated_at: 1698351687739,
	is_deleted: false,
};

const sensorsJsonExample: SensorDARList = [offlineSensorJsonExample, onlineSensorJsonExample];

export { offlineSensorJsonExample, onlineSensorJsonExample, sensorsJsonExample };
