import { useStore } from 'effector-react';
import { produce } from 'immer';
import { MouseEvent, useEffect, useMemo } from 'react';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import { CheckboxBase } from 'components/Checkbox';
import selectStyles from 'components/form/Select/index.module.css';
import MenuItem from 'components/MenuItem';
import PiiType from 'components/PiiType';
import Typo from 'components/typography/Typo';
import { DataTypeStructured } from 'models/dataTypes/dto';
import { getDataTypesFx } from 'models/dataTypes/effects';
import { getDataTypeCheckboxState, selectDataType } from 'models/dataTypes/helpers';
import { dataTypesStructuredStore } from 'models/dataTypes/store';
import { MapAsset } from '../../../index';
import { changeMapControls, mapControlsStore } from '../../model/store';
import styles from './index.module.css';

type Props = {
	mapAssets: MapAsset[];
};

function DataTypesFilter({ mapAssets }: Props) {
	const mapControls = useStore(mapControlsStore);
	const dataTypesStructured = useStore(dataTypesStructuredStore);

	useEffect(() => {
		getDataTypesFx();
	}, []);

	// For counters
	const usedDataTypeIds = useMemo(
		() => mapAssets.map(({ dataTypes }) => dataTypes).flat(1),
		[mapAssets]
	);

	const options = useMemo(
		() =>
			dataTypesStructured
				.map((dtGroup) => [dtGroup, dtGroup.child_items])
				.flat(2)
				.filter((dt) => dt.isUsed),
		[dataTypesStructured]
	);

	const selected = options.filter((dt) => mapControls.filter.dataTypes.includes(dt.id));

	const checkboxState = getDataTypeCheckboxState(options, selected);

	function onOptionClick(clickedOption: DataTypeStructured) {
		onChange(selectDataType(clickedOption, options, selected));
	}

	function onChange(selectedDataTypes: DataTypeStructured[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.dataTypes = selectedDataTypes.map((dt) => dt.id);
		});

		changeMapControls(newControls);
	}

	function onFilterReset(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		onChange([]);
	}

	return (
		<Accordion className={styles.container} defaultExpanded={true}>
			<AccordionSummary
				classes={{
					root: styles.accordionSummaryRoot,
					content: styles.accordionSummaryContent,
				}}
			>
				<Typo variant="D/Medium/Body-S">Data type</Typo>
				{selected.length > 0 && (
					<Typo
						variant="D/Medium/Meta"
						color="secondary"
						component="span"
						className={styles.selectedCount}
					>
						selected: {selected.length}
					</Typo>
				)}
				{selected.length > 0 && (
					<Typo
						variant="D/Medium/Meta"
						component="span"
						className={styles.resetLink}
						onClick={onFilterReset}
					>
						Reset
					</Typo>
				)}
			</AccordionSummary>

			<AccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
				<div
					className={styles.itemsListDataTypesFilter}
					data-test="data-map-filter-data-types-container"
				>
					{options.length > 0 ? (
						options.map((option) => {
							const { checked = false, halfPressed = false } = checkboxState[option.id] || {};
							const usedIn = usedDataTypeIds.filter((dtId) => dtId === option.id).length;

							return (
								<MenuItem size="extraSmall" onClick={() => onOptionClick(option)} key={option.id}>
									<CheckboxBase
										checked={checked}
										halfPressed={halfPressed}
										className={option.isGroup ? styles.checkbox : styles.checkboxOffset}
									/>

									{option.isGroup ? (
										option.name
									) : (
										<>
											<PiiType type={option.id} />{' '}
											<Typo variant="D/Regular/Meta" color="secondary" className={styles.usedId}>
												{usedIn}
											</Typo>
										</>
									)}
								</MenuItem>
							);
						})
					) : (
						<Typo variant="D/Regular/Body-S" color="secondary" className={selectStyles.noOptions}>
							No items
						</Typo>
					)}
				</div>
			</AccordionDetails>
		</Accordion>
	);
}

export default DataTypesFilter;
