import { useState, useMemo } from 'react';
import Typo from 'components/typography/Typo';
import { CheckEmailJson } from 'models/session/dto';
import signUpStyles from '../SignUp/index.module.pcss';
import SignInEmail from './SignInEmail';
import SignInPassword from './SignInPassword';
import SignInSSO from './SignInSSO';

function SignIn() {
	// Client can be given link to sign-in page with email in query params, for convenience.
	const initialEmail = useMemo(
		() => new URLSearchParams(window.location.search).get('email') || '',
		[]
	);

	const [signInStep, setSignInStep] = useState(
		<SignInEmail email={initialEmail} goToNextStep={goToNextStep} />
	);

	function goToNextStep(email: string, emailCheckResult: CheckEmailJson) {
		if (emailCheckResult.is_sso) {
			setSignInStep(<SignInSSO email={email} clearEmail={clearEmail} />);
		} else {
			setSignInStep(<SignInPassword email={email} clearEmail={clearEmail} />);
		}
	}

	function clearEmail() {
		setSignInStep(<SignInEmail email="" goToNextStep={goToNextStep} />);
	}

	return (
		<div className={signUpStyles.container} data-test="sign-in-container">
			<div className={signUpStyles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={signUpStyles.title}>
					Sign in
				</Typo>

				{signInStep}
			</div>
		</div>
	);
}

export default SignIn;
